<template>
    <div class="content">
        <router-view></router-view>
        <div class="top-img">
            <div class="banner-img" v-for="(item,index) in banner" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="content-1366">
            <div class="list-wrap">
                <div class="list" v-for="(item,i) in nav" :key="i" v-if="item.title=='网站首页' ? false:true">
                    <template>
                        <div class="list-top">
                            <div>
                                <img :src="item.bgimg" alt="">
                                <p>{{item.title}}</p>
                            </div>
                            <div>
                                <p style="font-size:12px;color:#444;cursor: pointer;" @click="hrefUrl(item.id,item.pid)">更多></p>
                            </div>
                            <div class="line">
                                <img src="static/jr/line.png" alt="">
                            </div>
                        </div>
                        <ul class="list-ul">
                            <li v-for="(listItem,i) in item.list" :key="i"  @click="cookie(listItem)" class="li-hover">
                                <span class="circular"></span>
                                <p class="ell">{{listItem.title}}</p>
                                <p class="time">{{listItem.ctime | timeLenght }}</p>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
            <div class="friendLinks"  v-if="tit=='开放大学'">
                <div style="display:flex;line-height:34px;">
                    <img src="static/jr/links.png" alt="" class="links-icon">
                    <span>友情链接</span>
                </div>
                <div v-for="(item, i) in friendshipLinks" :key="i">
                    <el-select v-model="item.title" :placeholder="item.title">
                        <el-option
                            v-for="(val, index) in item.urls"
                            :key="index"
                            :label="val.title"
                            :value="val.title"
                            @click.native="hrefUrl3(val.url)"
                            >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="350px">
            <p>您没有权限，请先登录！
                <el-button type="primary" style="margin-left:10px;" @click="login">登录</el-button>
            </p>
        </el-dialog>
    </div>
</template>

<script>
import JrApi from '../../../lvya-common/src/api/eos/jr'
import Cookies from 'js-cookie'
export default {
    name: 'top',
    data() {
        return {
            listAll: [],
            banner: '',
            list: [],
            nav: [],
            id2: '',
            dialogVisible: false,
            friendshipLinks: [
                {
                    title: '国家开放大学',
                    urls: [
                        {
                            title: '国家开放大学',
                            url: 'http://www.ouchn.edu.cn/'
                        }
                    ]
                },
                {
                    title: '江苏开放大学',
                    urls: [
                        {
                            title: '江苏开放大学',
                            url: 'http://www.jsou.cn'
                        }
                    ]
                },
                {
                    title: '市县级开放大学',
                    urls: [
                        {
                            title: '镇江开放大学',
                            url: 'http://cjc.zjc.edu.cn/'
                        },
                        {
                            title: '无锡开放大学',
                            url: 'http://www.wxtvu.cn/'
                        },
                        {
                            title: '徐州开放大学',
                            url: 'http://www.xztvu.cn/'
                        },
                        {
                            title: '常州开放大学',
                            url: 'http://www.czou.czedu.cn/'
                        },
                        {
                            title: '苏州开放大学',
                            url: 'http://www.sztvu.com'
                        },
                        {
                            title: '南通开放大学',
                            url: 'http://www.ntou.edu.cn/'
                        },
                        {
                            title: '连云港开放大学',
                            url: 'http://www.jslygou.cn/'
                        },
                        {
                            title: '淮安开放大学',
                            url: 'http://www.hakfdx.cn/'
                        },
                        {
                            title: '宿迁开放大学',
                            url: 'http://www.sqou.cn/'
                        },
                        {
                            title: '泰州开放大学',
                            url: 'http://jxjy.tzu.edu.cn/'
                        },
                        {
                            title: '盐城开放大学',
                            url: 'http://www.yctvu.ycit.cn'
                        },
                        {
                            title: '扬州开放大学',
                            url: 'https://kfdx.yzpc.edu.cn/'
                        }
                    ]
                },
            ]
        }
    },
    created() {
        this.urlGet()
        this.Nav()
    },
    watch: {
        '$route'(to, from) {
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
        }
    },
    methods: {
        cookie(listItem) {
            if (listItem.isrole == 'Y') {
                let EOSToken = Cookies.get('EOSToken')
                if (EOSToken) {
                    this.$router.push({ path: '/details', query: { id: listItem.id, pid: this.pid }})
                } else {
                    this.id = listItem.id
                    this.dialogVisible = true
                }
            } else {
                this.$router.push({ path: '/details', query: { id: listItem.id, pid: this.pid }})
            }
        },
        login() {
            window.location.href = `http://jrzz.lvya.org/eos-web/#/login?url=http://www.jsjrzz.net/#/details?id=${this.id}&pid=${this.pid}`
        },
        urlGet() {
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
            this.tit = this.$route.query.tit
            // console.log(this.tagid, this.id, this.pid)
        },
        // banner图
        async Banner() {
            const resData = await JrApi.Banner({ typeid: this.pid })
            this.banner = resData
            if (this.banner == '') {
                const resData1 = await JrApi.Banner()
                this.banner = resData1
            }
            // console.log(this.banner[0].img)
        },
        async Nav() {
            const resData = await JrApi.Nav({ pid: this.pid })
            let listArr = resData[0].stypes
            for (let i = 0; i < listArr.length; i++) {
                const res = await JrApi.Xxxw({ typeid: listArr[i].id })
                listArr[i].list = res.slice(0, 7)
                // console.log(listArr[i])
            }
            // let wzsy = listArr[0].title
            // if (wzsy == '网站首页') {

            // }
            // console.log(listArr[0].title)
            // listArr.forEach(async(item) => {
            //     const res = await JrApi.Xxxw({ typeid: item.id })
            //     item.list = res
            // })
            this.nav = listArr
            // console.log(this.nav)
        },
         // 跳转二级页面
        hrefUrl(id, pid) {
            this.$router.push({ path: '/list', query: { id, pid }})
        },
        // hrefUrl2(listItem) {
        //     this.$router.push({ path: '/details', query: { id: listItem.id, pid: this.pid }}) // , id2: this.id
        // },
        // 友情链接跳转
        hrefUrl3(url) {
            window.open(url, '_blank')
        },
    },
    mounted() {
        this.Banner()
    }
}
</script>

<style scope>
.content {
  width: 100%;
  background: #fff;
}
.top-img{
  width: 100%;
  height: 410px;
  min-width: 1200px;
}
.banner-img {
    width: 100%;
    height: 410px;
    min-width: 1200px;
    background: url('../../../../public/static/jr/banner2.png') center top no-repeat;
    /* background-size: 100% 100%; */
}
.top-img img{
    width: 100%;
    height: 100%;
}
.content-1366{
    width: 1200px;
    min-height: 600px;
    margin: 20px auto;
    background: #fff;
}
.list-wrap{
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}
.list{
    width: 30%;
    min-height: 300px;
    background: #fff;
    margin-bottom: 20px;
    margin: 0 1.5%;
}
.list-top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    position: relative;
}
.list-top div{
    display: flex;
}
.list-top div img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 6px;
}
.list-top div p{
    margin-block-start: 0em;
    margin-block-end: 0em;
    color: #881f26;
}
.line{
    width: 100%;
    position: absolute;
    bottom: -5px;
}
.line img{
    width: 100%!important;
    height: auto!important;
}
.list-ul{
    width: 100%;
    padding-left: 0;
    margin-block-start: 0.6em;
    margin-block-end: 0em;
    /* padding: 10px 0px; */
    box-sizing: border-box;
    margin-top: 20px;
}
.list-ul li{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    line-height: 30px;
}
.circular{
    width: 5px;
    height: 5px;
    display: inline-block;
    background: #ccc;
    border-radius: 50%;
    position: absolute;
    left: 0;
    bottom: 9px;
}
.ell{
    width: 70%;
    padding-left: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-block-start: 0em;
    margin-block-end: 0em;
}
.time{
    color: #888;
    margin-block-start: 0em;
    margin-block-end: 0em;
}
.li-hover{
    cursor: pointer;
}
.li-hover:hover{
    color: #881f26;
}
.friendLinks{
  width: 100%;
  display: flex;
}
.links-icon{
    width: 20px;
    height: 20px;
    margin-top: 7px;
    margin-right: 5px;
}
.friendLinks .el-select{
  margin: 0 30px;
}
.el-input__inner{
  border: 1px solid #ccc;
}
.el-input__inner::-webkit-input-placeholder{
    color:#666;
}
.el-input__inner::-moz-placeholder{
    color:#666;
}
.el-input__inner:-moz-placeholder{
    color:#666;
}
.el-input__inner:-ms-input-placeholder{
    color:#666;
}
</style>

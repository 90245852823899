<template>
  <div id="all" :class="{'hui': resData.ishui == 'Y'}">
    <Header></Header>
    <IndexHome></IndexHome>
    <!-- <router-view></router-view> -->
    <Footer></Footer>
  </div>
</template>

<script>

// 导入组件
import Header from '../components/subpageHeader'
import Footer from '../components/footer'
import IndexHome from './content'
import JrApi from '../../../lvya-common/src/api/eos/jr'

export default {
  name: 'App',
  components: {
    IndexHome,
    Header,
    Footer
  },
  data() {
    return {
      resData: '',
    }
  },
  methods: {
    async HF() {
      const resData = await JrApi.HF()
      this.resData = resData
      // console.log(resData)
    },
  },
  mounted() {
    this.HF()
  },
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
  list-style: none;
}
body {font-family: "Microsoft YaHei";}
.hui{
  -webkit-filter:grayscale(100%)
}
#all {
  width: 100%;
  font-family: 'Microsoft YaHei', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  margin-top: 0px;
  font-size: 16px;
}
</style>

<template>
    <div id="top">
        <div class="header-box">
            <div class="header-div">
                <div class="header-top">
                    <img src="http://eos.lvya.org/html/jurong/images/kfdx.png" alt="" class="logo" style="width:auto;"  v-if="tit=='开放大学'">
                    <img src="static/jr/logo2.png" alt="" class="logo" v-else>
                    <p v-for="(item,index) in columnList" :key="index"><span></span>{{item.title}}</p>
                </div>
            </div>
        </div>
        <div class="nav">
            <!-- <el-menu :default-active="activeIndex2"
                class="el-menu-demo subpage-nav"
                mode="horizontal"
                @select="handleSelect"
                background-color="#545c64"
                text-color="#fff"
                active-text-color="#ffd04b">
                <el-menu-item index="1">处理中心</el-menu-item>
                <el-submenu index="2">
                    <template slot="title">我的工作台</template>
                    <el-menu-item index="2-1">选项1</el-menu-item>
                    <el-menu-item index="2-2">选项2</el-menu-item>
                    <el-menu-item index="2-3">选项3</el-menu-item>
                    <el-submenu index="2-4">
                    <template slot="title">选项4</template>
                    <el-menu-item index="2-4-1">选项1</el-menu-item>
                    <el-menu-item index="2-4-2">选项2</el-menu-item>
                    <el-menu-item index="2-4-3">选项3</el-menu-item>
                    </el-submenu>
                </el-submenu>
            </el-menu> -->
            <el-menu :default-active="activeIndex"
                class="el-menu-demo subpage-nav"
                mode="horizontal"
                @select="handleSelect"
                background-color="#fff"
                text-color="#333"
                active-text-color="#333">
                <template v-for="(item,index) in nav">
                    <el-menu-item  :index="(index+1).toString()" :key="index" @click="tiao(item.title,item.id,item.pid)">{{item.title}}</el-menu-item>
                    <!-- <el-submenu :index="(index+1).toString()" :key="index">
                        <template slot="title">{{item.title}}</template>
                        <el-menu-item :index="index+1+'-'+index" v-for="(val,i) in item.stypes" :key="i"  @click="hrefUrl(val.tzurl,val.id,val.pid)">{{val.title}}</el-menu-item>
                    </el-submenu> -->
                </template>
                <div class="index-url" @click="indexUrl()">返回主站</div>
            </el-menu>
        </div>
    </div>
</template>

<script>
import JrApi from '../../../lvya-common/src/api/eos/jr'
export default {
    name: 'top',
    data() {
        return {
            status: false,
            isCustomers: -1,
            activeIndex: '',
            nav: '',
            columnList: [],
            tit: '',
        }
    },
    methods: {
        urlGet() {
            // this.tagid = this.$route.query.tagid
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
            this.tit = this.$route.query.tit
            // console.log(this.tit)
        },
        handleSelect(key, keyPath) {
            // console.log(key, keyPath)
        },
        // hrefUrl(url, id, pid) {
        //     if (url == '' || url == null) {
        //         console.log(id)
        //         this.$router.push({ path: '/list', query: { id, pid }})
        //     } else {
        //         this.$router.push({ path: url })
        //     }
        // },
        indexUrl() {
            this.$router.push({ path: '/home' })
        },
        tiao(tit, id, pid) {
            if (tit == '网站首页') {
                this.$router.push({ path: '/subpage', query: { id, pid }})
            } else {
                this.$router.push({ path: '/list', query: { id, pid }})
            }
        },
        // hrefUrlList(id, pid) {
        //     this.$router.push({ path: '/list', query: { id, pid }})
        // },
        banner() {
            if (this.tit == '工会') {
                // alert(111)
            }
        },
        async Nav() {
            const resData = await JrApi.Nav({ pid: this.pid })
            this.nav = resData[0].stypes
            // console.log(this.nav)
        },
        async ColumnList() {
            const resData = await JrApi.Yycenter({ pid: this.pid })
            this.columnList = resData
            // console.log(this.columnList)
        },
    },
    mounted() {
        this.urlGet()
        this.Nav()
        this.ColumnList()
        this.banner()
    },
}
</script>

<style scope>
@font-face {
 font-family: 'qigong';
 src: url('../../../font/qigong.TTF');
}
.header-box{
    width: 100%;
}
.header-div{
    width: 1200px;
    margin: 0 auto;
}
.header-top{
    height: 120px;
    display: flex;
}
.header-top img{
    width: 500px;
    height: 90px;
    margin-top: 10px;
}
.header-top p{
    font-size: 26px;
    color: #ec1d25;
    font-family: qigong;
    font-weight: 600;
    line-height: 110px;
}
.header-top p span{
    width: 8px;
    height: 8px;
    background: #ec1d25;
    border-radius: 50%;
    display: inline-block;
    margin: 30px 15px 0 15px;
}
.nav{
    width: 100%;
    background: #881f26;
    margin-top: 0px!important;
}
.nav ul{
    width: 1200px;
    margin: 0 auto;
    background-color: #881f26!important;
}
.el-menu.el-menu--horizontal {
    border-bottom: none;
}
 .nav ul li{
    color: #fff!important;
    background-color: #881f26!important;
}
.subpage-nav{
    display: flex;
}
.subpage-nav .el-submenu__title{
    background-color: #881f26!important;
    color: #fff!important;
}
.subpage-nav .el-submenu.is-active .el-submenu__title {
    border-bottom: 2px solid #fff;
    border-bottom-color: rgb(255, 255, 255)!important;
}
.subpage-nav .el-menu-item.is-active{
    border-bottom-color: rgba(255, 255, 255,0)!important;
}
.subpage-nav .el-submenu__title i{
    color: #fff!important;
}
.index-url{
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 14px;
    padding: 0 15px;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
}
</style>
